@use "../base";
@use "../variables" as *;
@use "../mixins/color" as *;
@use "../mixins/breakpoint" as *;

@use "../modules/theme";
@use "../modules/page";
@use "../modules/pageHero";
@use "../modules/title";
@use "../modules/titleHr";
@use "../modules/richText";
@use "../modules/boxedTexts";
@use "../modules/video";
@use "../modules/image";
@use "../modules/gmaps";
@use "../modules/ctaBand";
@use "../modules/ctaList";
@use "../modules/cta";
@use "../modules/teaser";
@use "../modules/share";
@use "../modules/sidebarPanel";
@use "../modules/sidebarSection";



.locationDetailMain {
    .pageHero .titleHr:after {
        min-width: 220px;
    }

    .page > div:first-child {
        position: relative;
        z-index: 1;
    }

    .page__wrap {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 2;
    }

    .boxedTexts h3 {
        border-bottom: 1px dashed $color-white;
        padding-bottom: 0.3em;
    }

    .richText ul,
    .boxedTexts ul {
        list-style: none;
        padding-left: 2em;
        padding-right: 2em;
    }

    .richText li,
    .boxedTexts li {
        position: relative;
    }

    .richText li:before {
        background: $color-jabil-blue;
    }

    .boxedTexts li:before {
        background: $color-white;
    }

    .richText li:before,
    .boxedTexts li:before {
        content: "";
        font-weight: bold;
        font-size: 1rem;
        display: inline-block;
        width: 0.6em;
        height: 0.6em;
        position: absolute;
        left: -2em;
        top: 0.4em;
    }

    .boxedTexts ul {
        @include breakpoint(md) {
            columns: 2;
            -webkit-columns: 2;
            -moz-columns: 2;
            column-gap: 60px;
        }
    }

    .boxText--singleColumn ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }

    .boxedTexts--certifications ul {
        font-size: 1.5rem;
        padding-left: 1.4em;
    }

    .boxedTexts--spaceValue ul {
        font-size: 2rem;
        padding-left: 1em;
    }

    .boxedTexts--certifications li:before {
        top: 0.75em;
    }

    .boxedTexts--spaceValue li:before {
        top: 1.2em;
    }

    .boxedTexts a {
        transition: 0.2s ease-in;
    }

    .boxedTexts a:hover {
        color: $color-grey-3c;
    }

    .gmaps {
        height: 18em;
        margin-bottom: 1.2em;

        @include breakpoint(lg) {
            height: 12em;
            margin-bottom: 3em;
        }
    }

    @include forColors() using ($color, $value) {
        &.colorTheme--#{$color} {
            .pageHero__bg {
                background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba($value, 1) 90%);
            }

            .richText li::before {
                background: $value;
            }
        }
    }

    .sidebar {
        margin-top: 3rem;
    }

    .sidebarPanel {
        margin-top: 3rem;

        .sidebarSection__title {
            border-bottom: 5px solid #4bbeeb;
            padding: 0 0 15px;
        }

        .ctaList--text > li {
            width: 100%;
            padding: 0;
            border-top: 1px dashed hsla(0, 0%, 100%, 0.7);
        }

        .ctaList--text > li:first-child {
            border-top-width: 0;
        }
    }

    .locationDetailMain__featuredMedia {
        margin-bottom: 1.2em;

        @include breakpoint(lg) {
            margin-bottom: 3em;
        }
    }

    .teaser__title {
        color: $color-white;
    }
}

.page__container {
  max-width: 1400px;
  padding: 2rem!important;
}
