@use "../variables" as *;
@use "../mixins/breakpoint" as *;

.boxedTexts {
    align-items: center;
    padding: 30px;
    margin-bottom: 20px;

    @media screen and (min-width: $width-desktop-min) {
        margin-bottom: 42px;
    }

    * {
        color: $color-white;
    }

    h3 {
        line-height: 1;
    }

    a {
        text-decoration: none;
    }
}

.boxedTexts--alignCenter {
    text-align: center;
}

.boxedTexts--alignLeft {
    text-align: left;
}

.boxedTexts--alignRight {
    text-align: right;
}

.boxedTexts--zebra:nth-child(even),
.boxedTexts--blue {
    background-color: $color-jabil-blue;
}

.boxedTexts--zebra:nth-child(odd),
.boxedTexts--lightGray {
    background-color: $color-grey-ad;
}

.boxedTexts__multipleLinks {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    @include breakpoint(md) {
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .boxedTexts__link {
        font-size: 1em;
        padding: 0.5em 0;

        &:not(:last-of-type) {
            border-bottom: 1px solid $color-white;
        }

        @include breakpoint(md) {
            padding: 0 1.5em;
            margin: 0;

            &:first-of-type {
                padding: 0 1.5em 0 0;
            }

            &:not(:last-of-type) {
                border-right: 2px solid $color-white;
                border-bottom: none;
            }
        }
    }
}
