@use "../variables" as *;

.sidebarSection__title {
    border-bottom: 5px solid #4bbeeb;
    color: $color-white;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    font-size: map-get($richTextHeaderSizes, h4);
    margin: 0;
    padding: 0 0 15px;
}

.theme--dark .sidebarSection {
    color: $color-white;
}

// CTA ITEMS MODS, TODO: can we refactor these into sidebar components?

.sidebarSection .cta__icon svg {
    height: 25px;
    width: 25px;
}

.sidebarSection .cta__title {
    font-size: 1rem;
}
