@use "../variables" as *;

.sidebarPanel {
    margin: 1rem 0 2.5rem 0;
    background: rgb(85, 118, 152);

    &:first-child {
        margin-top: 0;
    }

    > * {
        padding: 20px;
    }

    .video {
        padding: 0;
    }

    .button {
        display: block;
        margin: 8px 0;
        text-align: center;
    }
}

.sidebarPanel__title {
    border-bottom: 1px dashed hsla(0, 0%, 100%, 0.7);
    color: #fff;
    font-family: $font-lato;
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
}

.sidebarPanel--greenTheme {
    background: transparent;

    .sidebarSection {
        padding: 0;
    }

    .sidebarSection__title {
        color: $color-jabil-dark-yellow-green;
        border: none;
    }

    .ctaList {
        .teaser__title {
            color: $color-jabil-dark-yellow-green;
        }

        .teaser__icon {
            svg {
                fill: $color-jabil-dark-yellow-green;
            }
        }

        &.ctaList--text {
            & > li {
                border-bottom: 1px dashed $color-jabil-dark-yellow-green;

                &:first-child {
                    border-top: 1px dashed $color-jabil-dark-yellow-green;
                }
            }
        }
    }
}
