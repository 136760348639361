@use "../variables" as *;

.teaser {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.teaser__icon {
    display: flex;
    margin-right: map-get($spacing, 2);
}

.teaser__icon svg {
    height: 18px;
    width: 18px;
    fill: $color-white;
}

.teaser__title {
    color: $color-white;
    word-break: break-word;
}

.teaser:hover {
    .teaser__title {
        text-decoration: underline;
    }
}
